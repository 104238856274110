.form-container {
    margin: 0 auto;
}

textarea {
    resize: none;
}

.form-log {
    color: #fff;
}

input, textarea {
    width: 70%;
}

/*=====================
Contact Form Responsive
=====================*/
@media screen and (max-width:600px) {
    form input {
      width: 100%;
    }
  
    textarea {
      width: 100%;
    }
}
