.layout-container {
  /* background-color: #001427; */
  /* background-color: #242424; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center;
}

.text {
  margin-top: 5%;
  font-size: 1.1rem;
  text-align: center;
}

.container {
  width: 40%;
  margin: 0 auto;
  margin-top: 5%;
  padding: 10px;
  border: 1px solid #717171;
  border-radius: 5px;
  background-color: #373737;
  color: #FDF9F9;
}

.copyright-footer {
  color: #b8a07e;
  text-align: center;
  font-size: 1.2rem;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 0 10px 0;
}
