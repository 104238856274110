body {
  background: #050505;
  font-family: "Lora", serif;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.882;
  color: #000000;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
